import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

/**
 * Detects the whether the viewport is below the tablet range
 * or not.
 *
 * @param {function} callback A function that will be executed
 *    every time the viewport gets below Tailwind's "md"
 *    breakpoint.
 *
 * @returns {boolean} A reactive boolean that tracks whether
 *    the viewport is below the tablet range or not.
 */
export function useMobile(callback?: Function) {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isMobile = breakpoints.smaller('md')

  if (callback) {
    watch(isMobile, (value) => value && callback())
  }

  return isMobile
}

/**
 * Detects the whether the viewport is below the desktop range
 * or not.
 *
 * @param {function} callback A function that will be executed
 *    every time the viewport gets above Tailwind's "lg"
 *    breakpoint.
 *
 * @returns {boolean} A reactive boolean that tracks whether
 *    the viewport is below the desktop range or not.
 */
export function useMobileOrTablet(callback?: Function) {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isMobileOrTablet = breakpoints.smaller('lg')

  if (callback) {
    watch(isMobileOrTablet, (value) => value && callback())
  }

  return isMobileOrTablet
}

/**
 * Detects the whether the viewport is above the mobile range
 * or not.
 *
 * @param {function} callback A function that will be executed
 *    every time the viewport gets above Tailwind's "md"
 *    breakpoint.
 *
 * @returns {boolean} A reactive boolean that tracks whether
 *    the viewport is above the mobile range or not.
 */
export function useTabletOrDesktop(callback?: Function) {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isTabletOrDesktop = breakpoints.greater('md')

  if (callback) {
    watch(isTabletOrDesktop, (value) => value && callback())
  }

  return isTabletOrDesktop
}

/**
 * Detects the whether the viewport is above the tablet range
 * or not.
 *
 * @param {function} callback A function that will be executed
 *    every time the viewport gets above Tailwind's "lg"
 *    breakpoint.
 *
 * @returns {boolean} A reactive boolean that tracks whether
 *    the viewport is above the tablet range or not.
 */
export function useDesktop(callback?: Function) {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isDesktop = breakpoints.greater('lg')

  if (callback) {
    watch(isDesktop, (value) => value && callback())
  }

  return isDesktop
}
