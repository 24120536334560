<script setup lang="ts">
import { PropType } from 'vue'
import { useAppStore } from '~/stores/app'

const props = defineProps({
  variant: {
    type: String as PropType<'primary' | 'secondary' | 'tertiary' | 'unstyled'>,
    default: 'primary',
  },
  href: {
    type: String,
    required: true,
  },
  as: {
    type: String,
    default: 'a',
  },
})

const { $auth, $modal } = useNuxtApp()
const router = useRouter()
const app = useAppStore()
const showModalOrNavigate = () => {
  if (
    $auth.membershipStore.isSubscriberActive ||
    $auth.membershipStore.hasOnlyTrialActive
  ) {
    if (app.isDifferentAppVersion) {
      window.location.assign(props.href)
    } else {
      router.push(props.href)
    }
  } else if (!$auth.store.isAuthenticated) {
    $modal.open('login')
    $modal.loginRedirectionPath = props.href
  } else {
    $modal.open('subscribe')
  }
}
</script>

<template>
  <BaseLink
    data-testid="protected-link"
    :data-link="props.href"
    :href="href"
    :variant="variant"
    :as="as"
    :custom-navigation="showModalOrNavigate"
  >
    <slot />
  </BaseLink>
</template>
