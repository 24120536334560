<script setup lang="ts">
import { PropType } from 'vue'
import { Category, ContentType, Tag } from '@/models/content'
import BaseLink from '@/components/common/BaseLink.vue'
import ProtectedLink from '@/components/common/ProtectedLink.vue'

const props = defineProps({
  contentType: {
    type: String as PropType<ContentType>,
    default: 'article',
    validator(value: string) {
      return ['article', 'chart', 'video'].includes(value)
    },
  },
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  excerpt: {
    type: String,
    required: true,
  },
  pills: {
    type: Array as PropType<Tag[] | Category[]>,
    required: true,
  },
  pillsLimit: {
    type: Number,
    default: 2,
  },
  coverUrl: {
    type: String,
    required: true,
  },
  isProtected: {
    type: Boolean,
    default: false,
  },
  customContainerClasses: {
    type: String,
    default: '',
  },
})

const formattedDuration = useFormattedDuration(
  props.contentType,
  props.duration,
)
const { $cloudinary } = useNuxtApp()

const isVideo = computed(() => props.contentType === 'video')
const limitedPills = computed(() => props.pills.slice(0, props.pillsLimit))

const href = useContentHref(props.contentType, props.slug)
const coverPosition = isVideo.value
  ? 'object-left'
  : useCoverPosition(props.coverUrl)
const coverUrlResized = isVideo.value
  ? $cloudinary.resize(props.coverUrl, 'vertical-video-card-thumbnail')
  : $cloudinary.resize(props.coverUrl, 'vertical-card-thumbnail')
</script>

<template>
  <component
    :is="isProtected ? ProtectedLink : BaseLink"
    :href="href"
    variant="unstyled"
    as="a"
    class="h-full"
  >
    <div
      class="card-hover flex h-full flex-col bg-warm-gray"
      :class="customContainerClasses"
    >
      <!-- Cover -->
      <div class="relative w-full grow-0">
        <!-- Image -->
        <div class="relative w-full pb-9/16">
          <img
            width="500"
            height="500"
            class="absolute top-0 left-0 h-full w-full object-cover"
            :class="coverPosition"
            :src="coverUrlResized"
            alt=""
          />
          <div class="absolute h-full w-full border-2 border-black/10" />
        </div>

        <!-- Badges -->
        <div class="absolute top-0 left-0 flex w-full p-3">
          <div class="flex grow flex-wrap gap-2 pr-4">
            <LinkedPill
              v-for="pill in limitedPills"
              :key="pill.id"
              :type="pill.__typename"
              :slug="pill.slug"
            >
              {{ pill.name }}
            </LinkedPill>
          </div>

          <ContentTypeBadge :type="contentType" />
        </div>

        <!-- Duration badge -->
        <div v-if="isVideo" class="absolute bottom-0 right-0 p-3">
          <div
            class="rounded-3xl bg-black py-1.5 px-2.5 text-xxs font-semibold text-white"
            data-testid="vertical-card-duration"
          >
            {{ formattedDuration }}
          </div>
        </div>
      </div>

      <!-- Info -->
      <div class="flex grow flex-col justify-between p-3">
        <div>
          <h3
            class="custom-line-clamp text-sm font-semibold"
            :title="title"
            data-testid="vertical-card-title"
          >
            {{ title }}
          </h3>
          <div
            class="custom-line-clamp-lg mt-2 text-xs text-s-700"
            data-testid="vertical-card-excerpt"
            v-html="excerpt"
          />
        </div>
        <p
          class="text-xs font-semibold text-s-700"
          data-testid="vertical-card-date"
        >
          {{ publishedDate }}
          <span v-if="!isVideo">| {{ formattedDuration }}</span>
        </p>
      </div>
    </div>
  </component>
</template>
